import FooterSurgir from "layouts/FooterSurgir/FooterSurgir";
import SurgirNavbar from "layouts/navbar/rcf/Navbar";
import SurgirSearchContainer from "modules/components/Buscador/buscador";
import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MetaPixel from "utils/MetaPixel";
import Channels from "views/channels/Channels";
import ComplaintsBook from "views/complaints-book/ComplaintsBook";
import Contact from "views/contact/Contact";
import CreditInsurancePercentage from "views/credit-insurance-percentage/CreditInsurancePercentage";
import FixedDeposit from "views/fixed-deposit/FixedDeposit";
import FlexibleFixedDeposit from "views/flexible-fixed-deposit/FlexibleFixedDeposit";
import FormComplaintsBook from "views/form-complaints-book/FormComplaintsBook";
import FormDepositFixedSantander from 'views/form-deposit-fixed-santander/FormDepositFixSantander';
import FormDepositFixed from "views/form-deposit-fixed/FormDepositFixed";
import Home from "views/home/Home";
import Legal from 'views/legal/Legal';
import ListOfNotaries from "views/list-of-notaries/ListOfNotaries";
import PromotionHistory from "views/promotion-history/PromotionHistory";
import ResponseForm from "views/response-form/ResponseForm";
import ResultSearchView from "views/result-search-view/result-search-view";
import SavingsAccount from "views/savings-account/SavingsAccount";
import Transparency from 'views/transparency/Transparency';
import WhereWeAre from "views/where-we-are/WhereWeAre";
import LinkRoutes from "../constants/LinkRoutes";
import Simulator from "../modules/pages/Simulator/Simulator";
import About from "../views/about/About";
import CreditProsperous from '../views/credit-prosperous/CreditProsperous';
import CreditWomen from '../views/credit-women/CreditWomen';
import FrequencyQuestions from "../views/frequency-questions/FrequencyQuestions";
import RatesAndFees from "../views/rates-and-fees/RatesAndFees";
import RequestCredit from "../views/requestCredit/RequestCredit";
import Safe from "../views/safe/Safe";

const HomePage = () => (
  <>
    <MetaPixel event="PageView" />
    <Home />
  </>
);

const DepositoPlazoResponsePage = () => (
  <>
    <MetaPixel event="CompleteRegistration" />
    <ResponseForm />
  </>
);

const SolicitaTuCreditoResponsePage = () => (
  <>
    <MetaPixel event="Lead" />
    <ResponseForm />
  </>
);

export default class AdminRoutes extends Component {
  state = {
    modalVisible: false,
    cookiesVisible: true,
    searchContainer: false
  }

  loadReactGAScript() {
    ReactGA.initialize('UA-214558983-1', {
      nonce: 'f81d4c26afadd15dadbcd618376be2028d254ea7805155716a22cf2e1903f489'
    });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }

  loadDynatraceScript() {
    const script = document.createElement('script');
    script.id = 'dynatrace-script';
    script.src = `https://js-cdn.dynatrace.com/jstag/1672bab6729/bf35274bgd/${ process.env.REACT_APP_ID_DYNATRACE }_complete.js`;
    script.type = 'text/javascript';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  }

  componentDidMount() {
    this.loadReactGAScript();
    this.loadDynatraceScript();
  }

  goTop(){
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  changeVisibleModal = (value: any) => {
    this.setState({ modalVisible: value })
  }
  
  render() {
    return (
      <div className="position-relative">
        <SurgirNavbar onShowSearch={() => {
          this.setState({ searchContainer: !this.state.searchContainer })
        }}/>
        {this.state.searchContainer && <SurgirSearchContainer />} 
        <BrowserRouter>
          <Routes>
            <Route path={LinkRoutes.app} element={<HomePage />} />
            <Route path="/*" element={<Navigate to={LinkRoutes.app} />} />
            
            <Route path={LinkRoutes.creditWomen} element={<CreditWomen />} />
            <Route path={LinkRoutes.creditProsperous} element={<CreditProsperous />} />
            <Route path={LinkRoutes.promotionHistory} element={<PromotionHistory />} />
            <Route path={LinkRoutes.transparency} element={<Transparency />} />
            <Route path={LinkRoutes.resultSearchView} element={<ResultSearchView />} />
            <Route path={LinkRoutes.legal} element={<Legal />} />
            <Route path={LinkRoutes.complaintsBook} element={<ComplaintsBook />} />
            <Route path={LinkRoutes.complaintsBook + '/formulario'} element={<FormComplaintsBook />} />
            <Route path={LinkRoutes.flexibleFixedDeposit} element={<FlexibleFixedDeposit />} />
            <Route path={LinkRoutes.whereWeAre} element={<WhereWeAre />} />
            <Route path={LinkRoutes.channels} element={<Channels />} />
            <Route path={LinkRoutes.dpf} element={<FixedDeposit />} />
            <Route path={LinkRoutes.faqs} element={<FrequencyQuestions />} />
            {/* <Route path={LinkRoutes.creditInsurance} element={<CreditInsurance />} /> */}
            <Route path={LinkRoutes.creditInsurancePercentage} element={<CreditInsurancePercentage />} />
            <Route path={LinkRoutes.aboutUs} element={<About />} />
            <Route path={LinkRoutes.safe} element={<Safe />} />
            <Route path={LinkRoutes.contact} element={<Contact />} />
            <Route path={LinkRoutes.simulator} element={<Simulator/>} /> 
            <Route path={LinkRoutes.listOfNotaries} element={<ListOfNotaries />} />
            <Route path={LinkRoutes.ratesAndFees} element={<RatesAndFees />} />
            <Route path={LinkRoutes.depositFixedSantander} element={<FormDepositFixedSantander />} />
            <Route path={LinkRoutes.formDepositFixed} element={<FormDepositFixed />} />
            <Route path={LinkRoutes.savingsAccount} element={<SavingsAccount />} />
            <Route path={LinkRoutes.responseForm} element={<DepositoPlazoResponsePage />} />
            <Route path={LinkRoutes.requestCredit} element={<RequestCredit />} /> 
            <Route path={LinkRoutes.responseFormRequestCredit} element={<SolicitaTuCreditoResponsePage />} /> 

          </Routes>
          {this.state.cookiesVisible && <CookieConsent
            location="bottom"
            style={{ display: 'block', background: '#f5f9fb', textAlign: "left", color: "#000", padding: "20px" }}
            buttonStyle={{ color: "#fff", background: "#ec0000", fontSize: "14px", padding: "10px" }}
            buttonText="ACEPTAR TODAS LAS COOKIES"
            expires={365}
            cookieSecurity={true}
          >
            <div>
              <span className="cookies-title"> CONFIGURACIÓN DE COOKIES </span>
              <span>
                <br />
                Para <a style={{ fontFamily: 'Str-Headline-Bold', color: '#444444' }} > SURGIR Perú </a> es importante adaptarse a tus gustos y
                preferencias, para e                                 llo utilizamos cookies propias y de terceros para diferenciar su experiencia
                de las de otros usuarios, con fines analíticos y para mostrarle publicidad personalizada en base a
                un perfil elaborado a partir de sus hábitos de navegación.Puedes configurar las cookies o aceptar todas. Este banner
                se mantendrá activo hasta que ejecutes una de las dos opciones.
                Para más información consulta nuestra  <a style={{ textDecoration: 'none', color: '#ec0000' }} href="/legales"> Política de Cookies</a>
              </span>
            </div>
            <Button onClick={() => this.changeVisibleModal(true)} variant='outline-danger' style={{ width: 209, paddingLeft: 16, paddingRight: 16, borderRadius: 0, marginTop: 12, marginBottom: -17/*  fontWeight: 'bold' */ }}>
              CONFIGURAR COOKIES
            </Button>
          </CookieConsent>}
          <Modal show={this.state.modalVisible} onHide={() => this.changeVisibleModal(false)}>
            <Modal.Header closeButton >
              <Modal.Title>Configurar cookies:
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <li style={{ listStyle: 'none', verticalAlign: 'middle' }}>
                <label htmlFor="cbox1">
                  <input type="checkbox" disabled={true} checked={true} style={{ height: 16, width: 16, marginBottom: -20 }} />
                  <a style={{ fontFamily: 'Str-Microtext-Bold', color: '#000000' }} > Cookies funcionales:</a> Son las que permiten la navegación óptima y el acceso a áreas seguras de la página web, por
                  lo cual la página web no podrá funcionar adecuadamente sin estas cookies. Estas cookies no alamacenan ninguna información
                  de identificación personal.</label>
              </li>
              <li style={{ listStyle: 'none', verticalAlign: 'middle' }}>
                <label htmlFor="cbox2">
                  <input type="checkbox" id="cbox2" value="second_checkbox" style={{ height: 16, width: 16 }} />
                  <a style={{ fontFamily: 'Str-Microtext-Bold', color: '#000000' }} > Cookies analítica y optimización: </a>Son aquellas que permiten el editor de estas, el seguimiento y análisis del comportamiento
                  de los usuarios de los sitios web a los que están vinculados. La información recogida mediantes este tipo de cookies se utiliza en la medición
                  de la actividad de los sitios web, aplicación o plataforma, y para la elaboración de perfiles de navegación, con el fin de introducir
                  mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.</label>
                <p>Puede consultar nuestra política de cookies en cualquier momento aquí o en la web </p>
              </li>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={() => {
                this.changeVisibleModal(false);
                this.setState({
                  cookiesVisible: false
                })
                Cookies.set('CookieConsent', true, { path: '/', secure: true });
              }
              } style={{ color: "#fff", background: "#ec0000", fontSize: "14px", padding: "10px", border: "2px" }} variant="primary">GUARDAR Y ACEPTAR</Button>

            </Modal.Footer>
          </Modal>
        </BrowserRouter>

        {/* <FooterRCC /> */}
        <FooterSurgir backTop={this.goTop}></FooterSurgir>
      </div>

    );
  }
}
