import SurgirButton from 'components/ui/SurgirButton/SurgirButton';
import SurgirTopBanner from 'components/ui/SurgirTopBanner/SurgirTopBanner';
import React, { useEffect, useState } from 'react';
import { Navbar } from "react-bootstrap";
import './SurgirhoverList.scss';

interface Option {
	title: string;
	description: string;
	link: string;
	targetBlank: boolean;
	keywords: string;
}

interface Section {
	icon: string;
	title: string;
	options: Option[];
}

interface MenuItem {
	title: string;
	children: boolean;
	link: string;
	sections: Section[];
}

export interface SurgirhoverListProps {
	menuItems: MenuItem[];
	onSearch?: () => void;
}

interface DataNav {
	index: number;
	title: string;
	children: boolean;
	link: string;
	sections: Section[];
}

const SurgirhoverList: React.FC<SurgirhoverListProps> = ({ menuItems, onSearch }) => {
	const [hoveredItem, setHoveredItem] = useState<number | null>(null);
	const [transition, setTransition] = useState<boolean>(true);
	const [activeSearch, setActiveSearch] = useState(false);
	const [isDisplayed, setIsDisplayed] = useState(false);
	const [innerHeight, setInnerHeight] = useState(0);
	const [currentNav, setCurrentNav] = useState<DataNav | null>(null);
	const handleClick = (value: boolean) => {
		setActiveSearch(value);
		onSearch && onSearch();
	};

	const handleMouseEnter = (itemId: number, navData: MenuItem) => {
		setHoveredItem(itemId);
		handleDropdownHover(itemId);
		navData && setCurrentNav({...navData, index: itemId});
	};
	const handleMouseLeave = (menuItem: DataNav | null) => {
		(hoveredItem !== null && menuItem?.sections?.length) && setTransition(false);
		setHoveredItem(null);
		setCurrentNav(null);
	};

	const backdrop = (index: any) => {
		setTransition(true);
		setHoveredItem(null);
		const icon = document.getElementById(`rotar-${index}`)
		icon?.classList.add('rotate-icon')
		const dropdownItem = document.getElementById(`dropdown-item-${index}`);
		const desapear2 = document.getElementsByClassName(`backdrop `);
		const desapearArray2 = Array.from(desapear2);
		if (dropdownItem) {
			desapearArray2.forEach((element, index) => {
				element.classList.add('no-display');
			});
		}
	}

	const backdropOut = (itemId: number) => {
		const dropdownItem = document.getElementById(`dropdown-item-${itemId}`);
		const desapear2 = document.getElementsByClassName(`backdrop`);
		const desapearArray2 = Array.from(desapear2);
		if (dropdownItem) {
			desapearArray2.forEach((element, index) => {
				element.classList.remove('no-display');
			});
		}
		setHoveredItem(itemId);
		handleDropdownHover(itemId);
		const icon = document.getElementById(`rotar-${itemId}`)
		icon?.classList.remove('rotate-icon')

	};

	const handleDropdownHover = (itemId: number) => {
		const dropdownItem = document.getElementById(`dropdown-item-${itemId}`);
		const desapear = document.getElementsByClassName(`option-content1`);
		const desapear3 = document.getElementsByClassName(`option-content`);
		const desapear2 = document.getElementsByClassName(`full-width-content`);
		const desapearArray2 = Array.from(desapear2);
		const desapearArray3 = Array.from(desapear3);
		const desapearArray = Array.from(desapear);

		if (dropdownItem) {
			desapearArray2.forEach((element, index) => {
				if (index === 4) {
					element.classList.add('no-display');
				}
			});
			desapearArray.forEach((element, index) => {
				if (index !== 0 && index !== 1 ) {
					element.classList.add('no-display');
				}
			});
			desapearArray3.forEach((element, index) => {
				if (index !== 0 && index !== 1) {
					element.classList.add('fixer-display');
				}
			});

		}
	};


	const [openIndex, setOpenIndex] = useState<number | null>(null);
	const [rotations, setRotations] = useState<number[]>(Array(menuItems.length).fill(0));

	const [colorIcon, setColorIcon] = useState<string[]>(Array(menuItems.length).fill(0));

	const handleMenuItemClick = (index: number) => {

		setOpenIndex(openIndex === index ? null : index);
		const isOpening = openIndex !== index;
		const dropdownItem = document.getElementById(`dropdown-movile-${index}`);
		if (dropdownItem) {
			const allDropdowns = document.querySelectorAll('.option-content-movile');
			allDropdowns.forEach((dropdown, i) => {
				const htmlDropdown = dropdown as HTMLElement;
				if (i === index) {
					if (isOpening) {
						setTimeout(() => {
							htmlDropdown.style.opacity = "1";
							htmlDropdown.style.maxHeight = "500px";
							htmlDropdown.style.paddingBottom = "24px";
						}, 10);
					} else {
						htmlDropdown.style.opacity = "0";
						htmlDropdown.style.maxHeight = "0";
						htmlDropdown.style.paddingBottom = "0px";
					}
				} else {
					htmlDropdown.style.opacity = "0";
					htmlDropdown.style.maxHeight = "0";
					htmlDropdown.style.paddingBottom = "0";
				}
			});
		}

		setRotations(prevRotations => {
			const newRotations = prevRotations.map((rotation, i) => {
				if (i === index) {
					return isOpening ? 180 : 0;
				} else {
					return 0;
				}
			});

			return newRotations;
		});

		setColorIcon(prevColorsIcon => {
			const newColorsIcon = prevColorsIcon.map((image, i) => {
				if (i === index) {
					return '/icons/navbar/arrow_down_black.svg';
				} else {
					return isOpening ? '/icons/navbar/arrow_down_gray.svg': '/icons/navbar/arrow_down_black.svg';
				}

			});

			return newColorsIcon;
		});

		
	};

	const [scrollPosition, setScrollPosition] = useState(0);

	const [isTopScroll, setIsTopScroll] = useState(true);

	const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};

	const handleResize = () => {
		setInnerHeight(window.innerHeight - 48 - 38);
	};

	useEffect(() => {
		setInnerHeight(window.innerHeight - 48 - 38);
		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, [innerHeight]);

	useEffect(() => {
		setIsTopScroll(scrollPosition === 0);
	}, [scrollPosition]);
	const getClassForIndex = (currentIndex: number | null) => {
		if (currentIndex === openIndex) {
			return 'clicked';
		} else if (openIndex !== null) {
			return 'non-clicked';
		} else {
			return 'inactive-navbar';
		}
	};

	const getDropdownClass = (index: number) => {
		if (hoveredItem === index) {
			return 'hovered';
		} else if (hoveredItem !== null) {
			return 'non-hovered';
		} else {
			return 'inactive-navbar';
		}
	};

	
	const handleButtonClick = () => {
		window.location.href = '/abre-tu-deposito-a-plazo-fijo';
	};

	return (
		<Navbar className={`navbar-surgir ${isTopScroll ? '' : 'navbar-shadow nav-mobile-fixed'}`}>
			<SurgirTopBanner isTopScroll={isTopScroll} />

			<div className='content-responsiv ' >
				<div className='navbar content-responsive' >
					<a href="/">
						<img
							className="d-block logo"
							src="../logo_surgir_red.svg"
							alt="Logo"
						/>
					</a>
					<nav className={`nav-desktop ${activeSearch && 'mode-active-search'}`} onMouseLeave={() => setTransition(true)}>
						{menuItems.map((menuItem, index: any) => (

							<button
								id={`dropdown-item-${index}`}
								className=
								{`dropdown ${getDropdownClass(index)}`}
								key={menuItem.title}
								onMouseEnter={() => handleMouseEnter(index, menuItem)}
								onMouseLeave={() => !menuItem.sections.length && handleMouseLeave({...menuItem, index})}
							>
								<a href={menuItem.link || "#"}>
									{menuItem.title}
									{menuItem.sections && menuItem.sections.length > 0 && (
										<img
											id={`rotar-${index}`}
											className="d-block img arrow_up"
											src='/icons/navbar/arrow_down_black.svg'
											style={{ width: "12px", height: "12px" }}
											alt="arrow_up"
										/>
									)}
								</a>
							</button>
						))}

							<button
								className={`dropdown-content ${ (!isTopScroll && hoveredItem === currentNav?.index) ? 'scroll-fixer-desktop': ''} ${transition ? 'transition-nav' : ''} ${hoveredItem === currentNav?.index ? 'visible' : ''}`}
								onMouseLeave={() => currentNav && handleMouseLeave(currentNav)}
							>
								{
									currentNav && (currentNav.children && currentNav.sections.length) ? <>
									
									<button 
										className="backdrop d-block" 
										onMouseEnter={() => backdrop(currentNav.index)} 
										onMouseLeave={() => backdropOut(currentNav.index)} 
									>
									</button>
							
							<div className="dropdown-content-body">
								<div className="full-width-content">
									<div className="option-content1">
										{currentNav.children && hoveredItem === currentNav.index && (
											<ul>
												<div className="img-card">
													<p className="img-text">¡Abre tu Depósito a Plazo con esta súper tasa!</p>
													<SurgirButton
														onClick={handleButtonClick}
														radius={'50px'}
														fontFamily={"Str-Microtext-Bold"}
														width='137px'
														height='32px'
														marginTop="10px"
														variant="danger"
													>
														Solicítalo ahora
													</SurgirButton>
													<img
														className="d-block"
														src="../images/dpf/dpf-menu.png"
														alt="Alt_cumple tus metas con nuestra súper tasa ahorros inversión SURGIR depósito a plazo fijo Banner menú"
														width="100%"
														/>
													</div>
												</ul>
											)}
										</div>
										<div className="option-content opacity-100">
											{currentNav.children && hoveredItem === currentNav.index && (
												<ul>
													{currentNav.sections.map((section) => (
														<div key={section.title} className="option-card">
															<li >
																<p className="tittle-option-content">
																	<img 
																		className={`d-block img ${section.icon}`} 
																		style={{ width: "20px", height: "20px" }} 
																		alt="iconinfo" 
																	/>{section.title}</p>
																<ul className="ul-card">
																	{section.options.map((option) => (
																		<li key={option.link}>
																			<a className="option-card-li"
																				href={option.link}
																				target={option.targetBlank ? '_blank' : '_self'}
																				rel={option.targetBlank ? 'noopener noreferrer' : ''}
																			>
																				<p className='a-title'>{option.title}</p>
																				<p className='a-description'>{option.description}</p>
																			</a>
																		</li>
																	))}
																</ul>
															</li>
														</div>

													))}
												</ul>
											)}
										</div>
									</div>
								</div>
									
									</> : null
								}
								
							</button>
					
					</nav>
					<div className='nav-icons'>
						<div className="d-flex gap-3">
							
							{!isDisplayed && (
								<div className="container-search-icon">
									{activeSearch ? (
										<button
											className="d-block close-ic custom-button-icon"
											onClick={() => handleClick(false)}
										>
											<img
												alt="close"
											/>
										</button>

									) : (
										<button
											className="d-block custom-button-icon"
											onClick={() => handleClick(true)}
										>
											<img
												alt="search"
											/>
										</button>
										
									)}
								</div>
							)}
							<div className='container-search-icon'>
								{!activeSearch && isDisplayed ? (
										<button
											className="in-circle close-ic custom-button-icon"
											onClick={() => setIsDisplayed(false)}
										>
											<img
												alt="close"
											/>
										</button>
									) : (
										<button
											className="in-circle burger custom-button-icon"
											onClick={() => setIsDisplayed(true)}
										>
											<img
												alt="burger"
											/>
										</button>
									)}
						
				
							</div>
							
						</div>
					</div>

					<div className='nav-icons-movile'>
						<div className="d-flex gap-3">
							<div className="container-search-icon">
								{activeSearch ? (
									<button
										className="d-block close-ic custom-button-icon"
										onClick={() => handleClick(false)}
									>
										<img
											alt="close"
										/>
									</button> 
									
								) : (
									<button
										className="d-block custom-button-icon"
										onClick={() => handleClick(true)}
									>
										<img
											alt="search"
										/>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* {isDisplayed && ( */}
				<div className={`nav-mobile-content ${isTopScroll ? '' : 'scroll-fixer-mobile'}`} style={{ display: 'block', height: isDisplayed ? `100vh` : '0' }}>
					<div className="nav-movile" style={{ maxHeight: "100vh", height: "100%", margin: "auto" }}>
						{menuItems.map((menuItem, index) => (
							<button
								type='button'
								className={`custom-button dropdown-movile ${getClassForIndex(index)}`}
								key={menuItem.title}
								onClick={() => handleMenuItemClick(index)}
							>
								<a href={menuItem.link || "#"} onClick={() => handleMenuItemClick(index)}>
									{menuItem.title}
									{menuItem.sections && menuItem.sections.length > 0 && (
										<img
											className="d-block img"
											src={colorIcon[index] || '/icons/navbar/arrow_down_black.svg'}
											style={{ 
												width: "20px", 
												height: "20px", 
												transform: `rotate(${rotations[index]}deg)`,
											}}
											alt="arrow_down_black"
										/>
									)}
								</a>
								{menuItem.children && (
									<div id={`dropdown-movile-${index}`} className="option-content-movile block" style={{paddingBottom: ''}}>
										{menuItem.children && (
											<ul>
												{menuItem.sections.map((section) => (
													<div key={section.title} className="option-card" >
														<li>
															<p className="tittle-option-content-movile">
																{section.title}
															</p>
															<ul className="ul-card">
																{section.options.map((option) => (
																	<li key={option.link}>
																		<a
																			href={option.link}
																			target={option.targetBlank ? '_blank' : '_self'}
																			rel={option.targetBlank ? 'noopener noreferrer' : ''}
																		>
																			<p className='a-title'>{option.title}</p>
																		</a>
																	</li>
																))}
															</ul>
														</li>
													</div>
												))}
											</ul>
										)}
									</div>
								)}
							</button>
						))}
					</div>
				</div>
			{/* )} */}




		</Navbar>
	);
};

export default SurgirhoverList;
